.expirationDate {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  min-width: 150px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  margin-right: 40px;
}

.cardNumber {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  min-width: 340px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  margin-right: 40px;
}

.cardName {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  min-width: 300px;
  padding: 10px 14px;
  font-size: 1em;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  margin-right: 40px;
}

.cvc {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  min-width: 150px;
  padding: 10px 14px;
  font-size: 1em;
  font-family: 'Source Code Pro', monospace;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border: 0;
  outline: 0;
  border-radius: 4px;
  background: white;
  margin-right: 40px;
}

.checkout label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
}

.checkout form {
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.checkout input::placeholder {
  color: #aab7c4;
}

.StripeElement--focus,
.cardName:focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
