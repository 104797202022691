.list {
  margin-left: -35px;
  list-style: none;
}

.list__item {
  width: max-content;
  position: relative;
  color: #000000;
}
.list__item:hover > .label {
  color: #b42626;
}

.list__item:hover > .label::before {
  border: 0.3rem solid #b42626;
}

.radio-btn {
  position: absolute;
  opacity: 0.01;
}
.radio-btn:checked + .label {
  color: #363636;
}
.radio-btn:checked + .label::before {
  /* margin-right: 1rem; */
  border: 0.2rem solid rgb(97, 97, 97);
  background: #ffc108;
}

.label {
  z-index: 50000;
  display: inline-block;
  padding: 0rem 0;
  color: rgb(31, 31, 31);
  font-size: 1rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.25s linear;
}

.label::before {
  display: inline-block;
  vertical-align: middle;
  content: '';
  height: 1.125rem;
  width: 1.125rem;
  margin-right: 0.625rem;
  border: 0.2rem solid rgb(97, 97, 97);
  border-radius: 50%;
  transition: all 0.25s linear;
}
