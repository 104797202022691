@font-face {
  font-family: 'Poppins Vates';
  src: url('../fonts/poppins_vates-webfont.woff2') format('woff2'),
    url('../fonts/poppins_vates-webfont.woff') format('woff'),
    url('../fonts/poppins_vates-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html {
  font-family: sans-serif;
  font-size: 90%;
}

body {
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.header {
  background-color: #333333;
  margin-bottom: 15px;
}
.header-logo {
  margin-top: -8px;
  margin-right: 8px;
}
.header-logo-text {
  font-family: 'Poppins Vates', 'Open Sans', 'Helvetica Neue', Helvetica, Arial,
    sans-serif;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-size: 24px;
  color: #ffffff;
}
.header-item-user {
  align-self: center;
  color: white;
  cursor: pointer;
  margin-right: 0.5rem;
}
.header-item-badge {
  align-self: center;
  margin-right: 1rem;
}

.sm-center {
  max-width: 540px;
  margin: auto;
}
.md-center {
  max-width: 720px;
  margin: auto;
}
.lg-center {
  max-width: 960px;
  margin: auto;
}
.xl-center {
  max-width: 1140px;
  margin: auto;
}

.error-component {
  text-align: center;
  font-size: 36px;
  color: red;
}

.align-right {
  text-align: right;
}

.bindingChoice-label {
  width: 200px;
  margin-bottom: 0;
}
.bindingChoice-input {
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}
.hvr-grow:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.link {
  color: #007bff;
}
.link:hover {
  text-decoration: underline;
  color: #0056b3;
  cursor: pointer;
}

.card-title {
  font-weight: bold;
  font-size: 16px;
}
.order-id {
  color: #17a2b8;
  text-transform: uppercase;
  font-size: 14px;
}

.bold {
  font-weight: bold;
}

.footer {
  margin-top: 30px;
}

.summary-highlight {
  color: #b42626;
  font-weight: bold;
}

@media screen and (max-width: 576px) {
  .header-item-user,
  .header-item-badge {
    align-self: flex-start;
  }
}
